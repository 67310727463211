import React, { useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-grid-system"
import RichContentTextBlock from "./richContentTextBlock"
import background from "../images/background.svg"
import ReactModal from "react-modal"
import { LinkButton } from "./button"
import Gut from "./gut"

const TopBlock = ({ section }) => {
  ReactModal.defaultStyles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.55)",
      zIndex: 1000,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    content: {
      width: "70vw",
      padding: "0 !important",
      background: "#000",
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      borderRadius: "4px",
      outline: "none",
    },
  }
  const [open, setOpen] = useState(false)

  return (
    <Wrapper>
      {section.video && (
        <ReactModal
          isOpen={open}
          onRequestClose={() => setOpen(false)}
          ariaHideApp={false}
          closeTimeoutMS={500}
        >
          <video
            style={{ display: "block" }}
            autoPlay
            controls
            width="100%"
            poster={section.video.video.thumbnailUrl}
          >
            <source src={section.video.video.mp4Url} type="video/mp4" />
          </video>
        </ReactModal>
      )}
      <Container>
        <Row>
          <Col lg={6}></Col>
          <Col lg={5} offset={{ lg: 1 }}>
            <Gut gutter={1}>
              <RichContentTextBlock html={section.text} />
              {section.video && (
                <LinkButton as="button" onClick={() => setOpen(true)}>
                  Guarda il video
                </LinkButton>
              )}
            </Gut>
          </Col>
        </Row>
      </Container>
      <CustomBackground
        onClick={() => setOpen(true)}
        style={section.video ? { cursor: "pointer" } : {}}
      >
        <Img fluid={section.image.fluid} />
      </CustomBackground>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  background-image: url(${background});
  background-position: top right;
  background-repeat: no-repeat;
  background-size: auto;
  padding-top: 9rem;
  padding-bottom: 9rem;
  min-height: 40rem;
  display: flex;
  align-items: center;
  @media screen and (max-width: 767px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`

const CustomBackground = styled.div`
  padding-top: 3rem;
  padding-bottom: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  .gatsby-image-wrapper {
    height: 100%;
  }
  @media screen and (max-width: 991px) {
    display: none;
  }
`

export default TopBlock

import React from "react"
import { Container, Row, Col } from "react-grid-system"
import styled from "styled-components"
import ContactForm from "./contactForm"
import RichContentTextBlock from "./richContentTextBlock"

const ContactBlock = ({ body }) => {
  return (
    <Wrapper>
      <Container>
        <Row justify="center">
          <Col lg={8}>
            <Row>
              <Col lg={5}>
                <RichContentTextBlock html={body} />
              </Col>
              <Col lg={7}>
                <ContactForm />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div``

export default ContactBlock

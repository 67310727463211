import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, Row, Col } from "react-grid-system"

const Gut = ({ gutter, children }) => (
  <Wrapper gutter={gutter}>{children}</Wrapper>
)
const Wrapper = styled.div`
  & > * {
    margin-bottom: ${props => `${props.gutter}rem` || "2rem"};
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default Gut

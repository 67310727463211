import React from "react"
import { Container, Row, Col } from "react-grid-system"
import styled from "styled-components"
import { Display } from "../components/typography"
import DesignTokens from "../components/designTokens"

const GenericText = ({ title, description }) => {
  return (
    <Wrapper>
      <StyledContainer>
        <Row justify="center">
          <Title>
            <Col>
              <Display color={DesignTokens.colors.primary[500]}>
                {title}
              </Display>
            </Col>
          </Title>
          <div
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </Row>
      </StyledContainer>
    </Wrapper>
  )
}

const StyledContainer = styled(Container)`
  margin-top: 4rem;
`

const Wrapper = styled.div`
  min-height: 80vh;
`
const Title = styled(Row)`
  margin-bottom: 3rem;
`

export default GenericText

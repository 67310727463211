import React from "react"
import { Col, Container, Row } from "react-grid-system"
import TextBlock from "./textBlock"
import styled from "styled-components"

const TextGridBlock = ({ section }) => {
  // console.log(section)
  return (
    <Wrapper>
      <Container>
        <Row justify="center">
          <Col sm={10}>
            <Row>
              {section.textBlocks.map(textBlock => (
                <Col md={6} key={textBlock.id}>
                  <TextBlock title={textBlock.title} text={textBlock.text} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div``

export default TextGridBlock

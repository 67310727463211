import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"

const MapBlock = ({ embed }) => {
  return <Wrapper dangerouslySetInnerHTML={{ __html: embed }} />
}

const Wrapper = styled.div`
  background-color: ${DesignTokens.colors.primary[500]};
  iframe {
    margin: 0;
    display: block;
  }
`

export default MapBlock

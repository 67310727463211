import React from "react"
import styled from "styled-components"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import DesignTokens from "./designTokens"
import { Col, Container, Row } from "react-grid-system"
import { Heading } from "./typography"
import RichContentTextBlock from "./richContentTextBlock"

const TabBlock = ({ tabs, title }) => {
  // console.log(tabs)
  return (
    <Wrapper>
      <Container>
        <Row>
          <Col>
            <Title>
              <Heading>{title}</Heading>
            </Title>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={12} lg={10}>
            <Tabs>
              <StyledTabList>
                {tabs.map(tab => (
                  <Tab key={tab.id}>{tab.title}</Tab>
                ))}
              </StyledTabList>
              {tabs.map(tab => (
                <TabPanel key={tab.id}>
                  <PanelContent>
                    <RichContentTextBlock html={tab.text} />
                  </PanelContent>
                </TabPanel>
              ))}
            </Tabs>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

const Title = styled.div`
  text-align: center;
  margin-bottom: 3rem;
`

const Wrapper = styled.div`
  background-color: ${DesignTokens.colors.white};
`

const StyledTabList = styled(TabList)`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: ${DesignTokens.colors.black};
  & > li {
    background-color: ${DesignTokens.colors.primary[100]};
    color: ${DesignTokens.colors.tertiary[500]};
    padding: ${DesignTokens.spacing[6]} ${DesignTokens.spacing[7]};
    border: 1px solid ${DesignTokens.colors.primary[100]};
    border-bottom: 1px solid ${DesignTokens.colors.tertiary[100]};
    margin-bottom: 0;
    /* flex: 1; */
    &:hover {
      cursor: pointer;
      color: ${DesignTokens.colors.primary[500]};
    }
    &.react-tabs__tab--selected {
      color: ${DesignTokens.colors.primary[500]};
      background-color: white;
      font-weight: 600;
      border: 1px solid ${DesignTokens.colors.primary[500]};
      border-bottom: 1px solid white;
      z-index: 1;
    }
  }
  @media screen and (max-width: 767px) {
    overflow-x: scroll;
    justify-content: start;
  }
`

const PanelContent = styled.div`
  position: relative;
  top: -1px;
  padding: 3rem;
  border: 1px solid ${DesignTokens.colors.primary[500]};
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default TabBlock

import React from "react"
import styled from "styled-components"
import RichContentTextBlock from "./richContentTextBlock"
import { Lead } from "./typography"

const TextBlock = ({ title, text }) => {
  return (
    <Wrapper>
      <Lead>{title}</Lead>
      <RichContentTextBlock html={text} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
    padding: 4rem;
    background-color: #f7f7f7;
    margin-bottom: 2rem;
    & > * {
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
`

export default TextBlock

import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, Row, Col, ScreenClassRender } from "react-grid-system"
import { Heading } from "./typography"
import RichContentTextBlock from "./richContentTextBlock"
import Img from "gatsby-image"
import { LinkButtonTertiary } from "./button"

const ImageBlock = ({ image, text, alignment }) => (
  <ScreenClassRender
    render={screenClass => (
      <Wrapper>
        <Container>
          <Row justify="center">
            <Col sm={12} md={10}>
              <Row>
                <Col md={6} push={alignment ? undefined : { md: 6 }}>
                  <Box>
                    <RichContentTextBlock html={text} />
                  </Box>
                </Col>
                <Col md={6} pull={alignment ? undefined : { md: 6 }}>
                  {image && <Img fluid={image.fluid} />}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    )}
  />
)
const Wrapper = styled.div``

const Box = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 3rem;
  & > * {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default ImageBlock

import React from "react"
import { graphql } from "gatsby"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import ImageGridBlock from "../components/imageGridBlock"
import FeaturedProductBlock from "../components/featuredProductsBlock"
import CallToActionBlock from "../components/callToActionBlock"
import TopBlock from "../components/topBlock"
import TextGridBlock from "../components/textGridBlock"
import styled from "styled-components"
import ImageBlock from "../components/imageBlock"
import TabBlock from "../components/tabBlock"
import MapBlock from "../components/mapBlock"
import ContactBlock from "../components/contactBlock"
import GenericText from "../components/genericText"

const Page = ({ data: { page } }) => {
  // console.log(page)

  return (
    <Layout>
      <SEO title={page.title} />
      <Wrapper>
        {page.content.map(section => (
          <section key={section.id}>
            {section.model.apiKey === "image_grid_block" && (
              <ImageGridBlock section={section} />
            )}
            {section.model.apiKey === "featured_products_block" && (
              <FeaturedProductBlock section={section} />
            )}
            {section.model.apiKey === "call_to_action_block" && (
              <CallToActionBlock section={section} />
            )}
            {section.model.apiKey === "top_block" && (
              <TopBlock section={section} />
            )}
            {section.model.apiKey === "text_grid_block" && (
              <TextGridBlock section={section} />
            )}
            {section.model.apiKey === "tabs_block" && (
              <TabBlock tabs={section.textBlocks} title={section.title} />
            )}
            {section.model.apiKey === "accordion_block" && (
              <TextGridBlock section={section} />
            )}
            {section.model.apiKey === "image_block" && (
              <ImageBlock
                image={section.image}
                text={section.text}
                alignment={section.alignToRight}
              />
            )}
            {section.model.apiKey === "map_block" && (
              <MapBlock embed={section.embedCode} />
            )}
            {section.model.apiKey === "contact_block" && (
              <ContactBlock body={section.body} />
            )}
            {section.model.apiKey === "generic_text" && (
              <GenericText title={section.title} description={section.description} />
            )}
          </section>
        ))}
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  & > * {
    margin-bottom: 6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 767px) {
    & > * {
      margin-bottom: 2rem;
    }
  }
`

export default Page

export const query = graphql`
  query PageQuery($slug: String!) {
    page: datoCmsPage(slug: { eq: $slug }) {
      title
      id
      content {
        ... on DatoCmsTopBlock {
          id
          image {
            fluid(maxWidth: 1024, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
            originalId
          }
          text
          model {
            apiKey
          }
          video {
            url
            video {
              mp4Url
              thumbnailUrl(format: jpg)
            }
          }
        }
        ... on DatoCmsGenericText {
          id
          title
          description
          model {
            apiKey
          }
        }
        ... on DatoCmsTextGridBlock {
          id
          textBlocks {
            title
            text
            id
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTabsBlock {
          id
          textBlocks {
            title
            text
            id
          }
          title
          model {
            apiKey
          }
        }
        ... on DatoCmsAccordionBlock {
          id
          textBlocks {
            title
            text
            id
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageBlock {
          id
          image {
            fluid(maxWidth: 1024, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
            originalId
          }
          text
          alignToRight
          model {
            apiKey
          }
        }
        ... on DatoCmsImageGridBlock {
          id
          images {
            originalId
            fluid(maxWidth: 480, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          featuredImage {
            originalId
            fluid(maxWidth: 768, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          text
          model {
            apiKey
          }
        }
        ... on DatoCmsCallToActionBlock {
          id
          backgroundImage {
            originalId
            fluid(maxWidth: 1920, imgixParams: { fm: "jpg" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          slug
          text
          title
          model {
            apiKey
          }
        }
        ... on DatoCmsMapBlock {
          id
          embedCode
          model {
            apiKey
          }
        }
        ... on DatoCmsContactBlock {
          id
          body
          model {
            apiKey
          }
        }
      }
    }
  }
`
